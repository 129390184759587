body, html, #root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: black;
    color: white;
}
* {
    box-sizing: border-box;
}
#root {
    overflow: scroll;
    text-align: center;
}
#root:after {
    height: 100%;
    vertical-align: middle;
    content: '';
    display: inline-block;
    width: 0;
}